var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('scroll-layout',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"300px"}},[_c(_setup.ykcForm,{ref:"YkcForm",attrs:{"model":_setup.model,"rules":_setup.rules}},[_c(_setup.YkcFormItem,{attrs:{"label":"menu_type","prop":"menu_type"}},[_c('ykc-radio',{attrs:{"data":[
              {
                id: '0',
                name: '一级菜单',
              },
              {
                id: '1',
                name: '下级菜单',
              },
              {
                id: '2',
                name: '按钮',
              },
            ]},model:{value:(_setup.model.menu_type),callback:function ($$v) {_vm.$set(_setup.model, "menu_type", $$v)},expression:"model.menu_type"}})],1),_c(_setup.YkcFormItem,{attrs:{"label":"id","prop":"id","labelTip":'一级菜单1-100,二级菜单101-1000,三级菜单1001-10000,按钮10000-'}},[_c('ykc-input',{attrs:{"disabled":!!_setup.isEdit,"placeholder":"请输入唯一id"},model:{value:(_setup.model.id),callback:function ($$v) {_vm.$set(_setup.model, "id", $$v)},expression:"model.id"}})],1),(_setup.model.menu_type != 0)?_c(_setup.YkcFormItem,{attrs:{"prop":"parent_id","label":"上级菜单"}},[_c(_setup.SelectTree,{attrs:{"props":{
              value: 'id',
              label: 'route_name',
              children: 'children',
              disabled: 'disabled',
            },"options":_setup.menuTree,"clearable":true,"accordion":true},model:{value:(_setup.model.parent_id),callback:function ($$v) {_vm.$set(_setup.model, "parent_id", $$v)},expression:"model.parent_id"}})],1):_vm._e(),_c(_setup.YkcFormItem,{attrs:{"label":"menu_name","prop":"menu_name"}},[_c('ykc-input',{attrs:{"placeholder":"请输入菜单名称(按钮说明)"},model:{value:(_setup.model.menu_name),callback:function ($$v) {_vm.$set(_setup.model, "menu_name", $$v)},expression:"model.menu_name"}})],1),(_setup.model.menu_type == 0)?_c(_setup.YkcFormItem,{attrs:{"label":"menu_icon","prop":"menu_icon"}},[_c('ykc-input',{attrs:{"placeholder":"请输入菜单icon"},model:{value:(_setup.model.menu_icon),callback:function ($$v) {_vm.$set(_setup.model, "menu_icon", $$v)},expression:"model.menu_icon"}})],1):_vm._e(),(_setup.model.menu_type != 2)?_c(_setup.YkcFormItem,{attrs:{"label":"route_url","prop":"route_url"}},[_c('ykc-input',{attrs:{"placeholder":"请输入URL"},model:{value:(_setup.model.route_url),callback:function ($$v) {_vm.$set(_setup.model, "route_url", $$v)},expression:"model.route_url"}})],1):_vm._e(),(_setup.model.menu_type != 2)?_c(_setup.YkcFormItem,{attrs:{"label":"order_num","prop":"order_num"}},[_c('ykc-input',{attrs:{"placeholder":"请输入排序"},model:{value:(_setup.model.order_num),callback:function ($$v) {_vm.$set(_setup.model, "order_num", $$v)},expression:"model.order_num"}})],1):_vm._e(),_c(_setup.YkcFormItem,{attrs:{"label":"permission_code","prop":"permission_code"}},[_c('ykc-input',{attrs:{"placeholder":"请输入permission_code"},model:{value:(_setup.model.permission_code),callback:function ($$v) {_vm.$set(_setup.model, "permission_code", $$v)},expression:"model.permission_code"}})],1),_c('ykc-dropdown',{attrs:{"placeholder":"使用角色","clearable":true,"data":[
            {
              id: '1',
              name: '平台管理员',
            },
            {
              id: '2',
              name: '商户管理员',
            },
            {
              id: '3',
              name: '电站管理员',
            },
          ],"multiple":true},model:{value:(_setup.model.use_range),callback:function ($$v) {_vm.$set(_setup.model, "use_range", $$v)},expression:"model.use_range"}}),_c('br'),_c('br'),_c('el-button',{on:{"click":_setup.createMenu}},[_vm._v(_vm._s(_setup.isEdit ? '保存' : '新建'))]),_c('el-button',{on:{"click":_setup.resetModel}},[_vm._v("清空")])],1)],1),_c('div',{staticStyle:{"width":"700px","margin-left":"100px"}},[_c('el-button',{attrs:{"size":"mini"}},[_c('input',{attrs:{"type":"file"},on:{"change":_setup.importJson}}),_vm._v(" 导入JSON ")]),_c('el-button',{attrs:{"size":"mini"},on:{"click":_setup.exportJson}},[_vm._v("导出JSON")]),_c('el-button',{attrs:{"size":"mini"},on:{"click":_setup.exportSql}},[_vm._v("导出Sql")]),_c('br'),_c('br'),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){$event.stopPropagation();return (() => _setup.addChild()).apply(null, arguments)}}},[_vm._v("新增一级菜单")]),_c('div',{staticStyle:{"width":"100%","height":"600px","overflow":"auto"}},[_c('el-tree',{ref:"selectTree",attrs:{"data":_setup.menuTree,"accordion":true,"default-expand-all":true,"props":{
            value: 'id',
            label: 'route_name',
            children: 'children',
            disabled: 'disabled',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(_vm._s(node.label))]),_c('span',[_vm._v("【"+_vm._s(data.use_range)+"】")]),_c('span',[(data.menu_type != 2)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (() => _setup.addChild(data)).apply(null, arguments)}}},[_vm._v(" 新增 ")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (() => _setup.editRow(data)).apply(null, arguments)}}},[_vm._v(" 编辑 ")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return (() => _setup.deleteRow(data)).apply(null, arguments)}}},[_vm._v(" 删除 ")])],1)])}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }