<template>
  <scroll-layout>
    <div style="display: flex">
      <div style="width: 300px">
        <ykc-form ref="YkcForm" :model="model" :rules="rules">
          <ykc-form-item label="menu_type" prop="menu_type">
            <ykc-radio
              :data="[
                {
                  id: '0',
                  name: '一级菜单',
                },
                {
                  id: '1',
                  name: '下级菜单',
                },
                {
                  id: '2',
                  name: '按钮',
                },
              ]"
              v-model="model.menu_type"></ykc-radio>
          </ykc-form-item>

          <ykc-form-item
            label="id"
            prop="id"
            :labelTip="'一级菜单1-100,二级菜单101-1000,三级菜单1001-10000,按钮10000-'">
            <ykc-input
              v-model="model.id"
              :disabled="!!isEdit"
              placeholder="请输入唯一id"></ykc-input>
          </ykc-form-item>

          <ykc-form-item prop="parent_id" label="上级菜单" v-if="model.menu_type != 0">
            <SelectTree
              :props="{
                value: 'id',
                label: 'route_name',
                children: 'children',
                disabled: 'disabled',
              }"
              :options="menuTree"
              v-model="model.parent_id"
              :clearable="true"
              :accordion="true" />
          </ykc-form-item>

          <ykc-form-item label="menu_name" prop="menu_name">
            <ykc-input v-model="model.menu_name" placeholder="请输入菜单名称(按钮说明)"></ykc-input>
          </ykc-form-item>

          <ykc-form-item label="menu_icon" prop="menu_icon" v-if="model.menu_type == 0">
            <ykc-input v-model="model.menu_icon" placeholder="请输入菜单icon"></ykc-input>
          </ykc-form-item>

          <ykc-form-item label="route_url" prop="route_url" v-if="model.menu_type != 2">
            <ykc-input v-model="model.route_url" placeholder="请输入URL"></ykc-input>
          </ykc-form-item>

          <ykc-form-item label="order_num" prop="order_num" v-if="model.menu_type != 2">
            <ykc-input v-model="model.order_num" placeholder="请输入排序"></ykc-input>
          </ykc-form-item>

          <ykc-form-item label="permission_code" prop="permission_code">
            <ykc-input
              v-model="model.permission_code"
              placeholder="请输入permission_code"></ykc-input>
          </ykc-form-item>

          <ykc-dropdown
            v-model="model.use_range"
            placeholder="使用角色"
            :clearable="true"
            :data="[
              {
                id: '1',
                name: '平台管理员',
              },
              {
                id: '2',
                name: '商户管理员',
              },
              {
                id: '3',
                name: '电站管理员',
              },
            ]"
            :multiple="true"></ykc-dropdown>
          <br />
          <br />
          <el-button @click="createMenu">{{ isEdit ? '保存' : '新建' }}</el-button>
          <el-button @click="resetModel">清空</el-button>
          <!-- <el-button @click="exportTabel">导出</el-button> -->
        </ykc-form>
      </div>

      <div style="width: 700px; margin-left: 100px">
        <el-button size="mini">
          <input type="file" @change="importJson" />
          导入JSON
        </el-button>
        <el-button size="mini" @click="exportJson">导出JSON</el-button>
        <el-button size="mini" @click="exportSql">导出Sql</el-button>
        <br />
        <br />
        <el-button size="mini" @click.stop="() => addChild()">新增一级菜单</el-button>
        <div style="width: 100%; height: 600px; overflow: auto">
          <el-tree
            ref="selectTree"
            :data="menuTree"
            :accordion="true"
            :default-expand-all="true"
            :props="{
              value: 'id',
              label: 'route_name',
              children: 'children',
              disabled: 'disabled',
            }">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span>【{{ data.use_range }}】</span>
              <span>
                <el-button
                  v-if="data.menu_type != 2"
                  type="text"
                  size="mini"
                  @click.stop="() => addChild(data)">
                  新增
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => editRow(data)">
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => deleteRow(data)">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <!-- <ykc-table
      ref="YkcTable"
      :border="true"
      :data="tableMenu"
      :columns="tableColumns"
      :title="'权限数据表'"
      :headerButtons="[]"
      :operateButtons="operateButtons"></ykc-table> -->
  </scroll-layout>
</template>

<script setup>
  /* eslint-disable */
  import { ref, onMounted, getCurrentInstance } from 'vue';
  import dayjs from 'dayjs';
  import ykcForm from '@/components/ykcForm.vue';
  import YkcFormItem from '@/components/ykc-ui/form/src/ykc-form-item.vue';
  import SelectTree from './treeSelect.vue';
  // import * as xlsx from 'xlsx';

  const { proxy } = getCurrentInstance();
  const getDefaultModel = () => ({
    id: '',
    menu_type: '0', // 类型 0 1 2
    parent_id: '0',
    menu_name: '', // 菜单名称
    menu_icon: '', // 菜单icon 只有0的才有
    route_name: '', // 同 menu_name
    route_url: '', // 前端路由
    redirect: '',
    component: '',
    permission_name: '',
    order_num: '1', // 排序
    permission_code: '', // permission_code
    level: '1', // 层级 1,2,3 可根据tree自动生成
    status: '0', // 目录状态(0正常 1停用 默认0)
    root_flag: '1', // 是否是根路由 0是 1否 默认1
    visible: '0', // 显示状态(0 显示 1隐藏 默认0)
    cache_flag: '1', // 是否缓存(0 缓存 1 不缓存 默认1)
    frame_flag: '1', // 是否外链(0 是 1 否 默认1)
    frame_src: '', // 外链地址
    affix: '1', // 是否固定在tab上(0是 1 否 默认1)
    always_show: '1',
    use_range: [], // 使用范围（1平台 2商户 3电站管理 默认1）多个用逗号隔开
    created_by: '0',
    created_time: '6/4/2023 13:13:40',
    modified_by: '0',
    modified_time: '6/4/2023 13:13:40',
    deleted: '0',
  });

  const isEdit = ref(false);
  const model = ref({});
  model.value = getDefaultModel();

  const rules = ref({
    id: [
      { required: true, message: '请输入id', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          const target = tableMenu.value.find(item => item.id == value);
          if (target && !isEdit.value) {
            callback(new Error(`id和${target.route_name}的id重复`));
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
    menu_type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
    parent_id: [
      {
        validator: (rule, value, callback) => {
          if (model.value.menu_type != 0 && value == 0) {
            callback(new Error('请选择上级菜单'));
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
    menu_name: [{ required: true, message: '请输入菜单名称(按钮说明)', trigger: 'blur' }],
    menu_icon: [
      { required: model.value.menu_type == 0, message: '请输入图标名称', trigger: 'blur' },
    ],
    route_url: [
      {
        validator: (rule, value, callback) => {
          if (value) {
            const target = tableMenu.value.find(item => item.route_url == value);
            if (target && !isEdit.value) {
              callback(new Error(`路由和${target.route_name}的路由重复`));
            }
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
    order_num: [{ required: model.value.menu_type != 2, message: '请输入顺序', trigger: 'blur' }],
    permission_code: [
      { required: true, message: '请输入权限code', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          const target = tableMenu.value.find(item => item.permission_code == value);
          if (target && !isEdit.value) {
            callback(new Error(`权限和${target.route_name}的权限重复`));
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
    use_range: [{ required: true, message: '请选择使用角色', trigger: 'blur' }],
  });

  // 最终的菜单tree
  const menuTree = ref([]);

  // 菜单tree平铺为table
  const tableMenu = ref([]);

  const init = () => {
    // 根据tree转table
    // const storageTree = localStorage.getItem('luoshu-menu');
    // if (storageTree) {
    //   menuTree.value = JSON.parse(storageTree);
    //   sortTree(menuTree.value);
    //   generateTable();
    //   saveMenu();
    // }
    // 根据table转tree
    const storageTable = localStorage.getItem('luoshu-table');
    if (storageTable) {
      tableMenu.value = JSON.parse(storageTable);
      generateTree();
      sortTree(menuTree.value);
      generateTable();
      saveMenu();
    }
  };

  onMounted(() => {
    init();
  });

  const tableColumns = [
    { label: 'id', prop: 'id', type: 'number', minWidth: '50px' },
    { label: 'menu_type', prop: 'menu_type', type: 'number', minWidth: '50px' },
    { label: 'parent_id', prop: 'parent_id', type: 'number', minWidth: '50px' },
    { label: 'menu_name', prop: 'menu_name', minWidth: '50px' },
    { label: 'menu_icon', prop: 'menu_icon', minWidth: '50px' },
    { label: 'route_name', prop: 'route_name', minWidth: '50px' },
    { label: 'route_url', prop: 'route_url', minWidth: '50px' },
    { label: 'redirect', prop: 'redirect', minWidth: '50px' },
    { label: 'order_num', prop: 'order_num', type: 'number', minWidth: '50px' },
    { label: 'component', prop: 'component', minWidth: '50px' },
    { label: 'permission_code', prop: 'permission_code', minWidth: '50px' },
    { label: 'permission_name', prop: 'permission_name', minWidth: '50px' },
    { label: 'level', prop: 'level', type: 'number', minWidth: '50px' },
    { label: 'status', prop: 'status', type: 'number', minWidth: '50px' },
    { label: 'root_flag', prop: 'root_flag', type: 'number', minWidth: '50px' },
    { label: 'visible', prop: 'visible', type: 'number', minWidth: '50px' },
    { label: 'cache_flag', prop: 'cache_flag', type: 'number', minWidth: '50px' },
    { label: 'frame_flag', prop: 'frame_flag', type: 'number', minWidth: '50px' },
    { label: 'frame_src', prop: 'frame_src', minWidth: '50px' },
    { label: 'affix', prop: 'affix', type: 'number', minWidth: '50px' },
    { label: 'always_show', prop: 'always_show', type: 'number', minWidth: '50px' },
    { label: 'use_range', prop: 'use_range', minWidth: '50px' },
    { label: 'created_by', prop: 'created_by', type: 'number', minWidth: '50px' },
    { label: 'created_time', prop: 'created_time', type: 'time', minWidth: '50px' },
    { label: 'modified_by', prop: 'modified_by', type: 'number', minWidth: '50px' },
    { label: 'modified_time', prop: 'modified_time', type: 'time', minWidth: '50px' },
    { label: 'deleted', prop: 'deleted', type: 'number', minWidth: '50px' },
  ];

  const YkcForm = ref(null);
  const createMenu = () => {
    YkcForm.value.validate(valid => {
      if (valid) {
        if (isEdit.value) {
          const item = findTreeChildById(menuTree.value, isEdit.value.id);
          Object.assign(item, {
            ...model.value,
            route_name: model.value.menu_name,
            use_range: model.value.use_range.join(','),
          });
          isEdit.value = false;
          sortTree(menuTree.value);
          generateTable();
          saveMenu();
          resetModel();
          return;
        }
        if (model.value.menu_type === 0) {
          menuTree.value.push({
            ...model.value,
            route_name: model.value.menu_name,
            menu_icon: model.value.menu_icon,
            level: 1,
            use_range: model.value.use_range.join(','),
            children: [],
          });
        } else {
          const parent = findTreeChildById(menuTree.value, model.value.parent_id);
          if (!parent) {
            this.$message.warning('parent 节点没找到');
          }
          parent.children.push({
            ...model.value,
            route_name: model.value.menu_name,
            menu_icon: '',
            level: String(Number(parent.level) + 1),
            use_range: model.value.use_range.join(','),
            children: [],
          });
        }
        sortTree(menuTree.value);
        generateTable();
        saveMenu();
        resetModel();
      }
    });
  };

  const resetModel = () => {
    isEdit.value = false;
    model.value = getDefaultModel();
    YkcForm.value.resetFields();
  };

  const saveMenu = () => {
    menuTree.value = [...menuTree.value];
    tableMenu.value = [...tableMenu.value];
    localStorage.setItem('luoshu-menu', JSON.stringify(menuTree.value));
    localStorage.setItem('luoshu-table', JSON.stringify(tableMenu.value));
  };

  const operateButtons = [
    {
      text: '编辑',
      handleClick: (btn, { row }) => {
        isEdit.value = { ...row };
        model.value = {
          ...row,
          use_range: row.use_range.split(','),
        };
      },
    },
    {
      text: '删除',
      handleClick: (btn, { row }) => {
        const parent = findTreeChildById(menuTree.value, row.parent_id);
        if (parent) {
          const index = parent.children.findIndex(cd => cd.id == row.id);
          if (index != -1) {
            parent.children.splice(index, 1);
            sortTree(menuTree.value);
            generateTable();
            saveMenu();
          }
        }
      },
    },
  ];

  const addChild = row => {
    resetModel();
    if (row) {
      model.value.parent_id = row.id;
      model.value.menu_type = row.id > 1000 ? '2' : '1';
      model.value.level = String(+row.level + 1);
      if (model.value.menu_type != 2) {
        const childOrderNums = row.children.map(item => item.order_num || 1);
        model.value.order_num = Math.max.apply(Math, childOrderNums) + 1;
      }
    } else {
      const childOrderNums = menuTree.value.map(item => item.order_num || 1);
      model.value.order_num = Math.max.apply(Math, childOrderNums) + 1;
    }
    model.value.id = getMaxId(model.value.level);
  };

  const editRow = row => {
    isEdit.value = { ...row };
    model.value = {
      ...row,
      use_range: row.use_range.split(','),
    };
  };

  const deleteRow = row => {
    const parent = findTreeChildById(menuTree.value, row.parent_id);
    if (parent) {
      const index = parent.children.findIndex(cd => cd.id == row.id);
      if (index != -1) {
        parent.children.splice(index, 1);
        sortTree(menuTree.value);
        generateTable();
        saveMenu();
      }
    }
  };

  function importJson(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = res => {
      try {
        const jsonMenu = JSON.parse(res.target.result);
        localStorage.setItem('luoshu-table', JSON.stringify(jsonMenu.RECORDS));
        init();

        this.$message.success('导入成功');
      } catch (e) {
        this.$message.error('导入失败，打开控制台看报错信息');
        console.error('导入错误信息：', e);
      }
    };
  }

  // function importTable(e) {
  //   // 获取excel文档
  //   const file = e.target.files[0];
  //   readWorkbookFromLocalFile(file, workbook => {
  //     let tableList = [];
  //     const sheetNames = workbook.SheetNames;
  //     sheetNames.map((sheetName) => {
  //       // 生成cvs 文档
  //       const csv = xlsx.utils.sheet_to_csv(workbook.Sheets[sheetName]);
  //       const csvList = csv.split('\n').filter(v => v != ',,,,');
  //       tableList.push(csvList);
  //     });
  //     console.log('tableList', tableList);
  //   });
  // }

  // function readWorkbookFromLocalFile(file, callback) {
  //   // 使用FileReader对象存储xslx文档
  //   const reader = new FileReader();

  //   reader.onload = function (e) {
  //     const data = e.target.result;
  //     // type一定要使用binary
  //     const workbook = xlsx.read(data, { type: 'binary' });

  //     if (callback) callback(workbook);
  //   };

  //   reader.readAsBinaryString(file);
  // }

  function exportJson() {
    const link = document.createElement('a');
    link.download = 't_user_menu.json';
    link.href =
      'data:text/plain,' +
      JSON.stringify({
        RECORDS: tableMenu.value,
      });
    link.click();
  }

  function exportSql() {
    const keys = tableColumns.map(item => ({
      key: item.prop,
      type: item.type || 'string',
    }));
    let sqlText = 'TRUNCATE table `omp_resource`.t_user_menu;\n';
    //增加\t为了不让表格显示科学计数法或者其他格式
    tableMenu.value
      .sort((a, b) => a.id - b.id)
      .forEach(item => {
        const values = keys
          .map(({ key, type }) => {
            if (item[key] === undefined || item[key] === '') return 'NULL';
            if (type === 'number') return Number(item[key]);
            if (type === 'time') return JSON.stringify(dayjs().format('YYYY-MM-DD hh:mm:ss'));
            return JSON.stringify(item[key]);
          })
          .join(',');
        sqlText += 'INSERT INTO `omp_resource`.`t_user_menu` VALUES (' + values + ');\n';
      });
    const link = document.createElement('a');
    link.download = 't_user_menu.sql';
    link.href = 'data:text/plain,' + sqlText;
    link.click();
  }

  function exportTabel() {
    const keys = tableColumns.map(item => item.label);
    let str = keys.join(',') + '\n';
    //增加\t为了不让表格显示科学计数法或者其他格式
    for (let i in tableMenu.value) {
      const item = tableMenu.value[i];
      for (let j of keys) {
        str += `${JSON.stringify(item[j])},`;
      }
      str += '\n';
    }
    //encodeURIComponent解决中文乱码
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    //通过创建a标签
    let link = document.createElement('a');
    link.href = uri;
    link.download = 'luoshumenu.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function findTreeChildById(tree, id) {
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i];
      if (item.id == id) {
        return item;
      }
      if (item.children && item.children.length) {
        const target = findTreeChildById(item.children, id);
        if (target) {
          return target;
        }
      }
    }
    return null;
  }

  function sortTree(tree) {
    tree.sort((a, b) => a.order_num - b.order_num);
    tree.map(item => sortTree(item.children));
  }

  function loopPush(tree, table) {
    tree.map(item => {
      const t = { ...item };
      delete t.children;
      table.push(t);
      loopPush(item.children, table);
    });
  }

  function generateTable() {
    const tableData = [];
    loopPush(menuTree.value, tableData);
    tableMenu.value = tableData;
  }

  function generateTree() {
    const data = tableMenu.value;
    let result = [];
    let obj = {};
    data.forEach(item => {
      obj[item.id] = Object.assign(item, obj[item.id] || { children: [] });
      if (item.parent_id && item.parent_id != 0) {
        let parent = obj[item.parent_id] || {};
        parent.children = parent.children || [];
        parent.children.push(item);
        obj[item.parent_id] = parent;
      } else {
        result.push(obj[item.id]);
      }
    });
    menuTree.value = result;
  }

  function getMaxId(menuLevel) {
    console.log('menuLevel', menuLevel);
    // 0,1,2
    const menuIds = tableMenu.value.filter(m => m.level == menuLevel).map(m => m.id);
    return String(+Math.max.apply(Math, menuIds) + 1);
  }
</script>
